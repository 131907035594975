import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [3,8,5,9];

export const dictionary = {
		"/(storefront)": [~11,[3],[4]],
		"/(storefront)/admin": [16,[3,8],[4]],
		"/(storefront)/admin/email": [~17,[3,8],[4]],
		"/(storefront)/admin/email/preview": [~18,[3,8],[4]],
		"/(storefront)/cadastrar": [~19,[3],[4]],
		"/(storefront)/(checkout)/carrinho": [12,[5,6]],
		"/(storefront)/contato": [20,[3],[4]],
		"/(marketing)/desinscreva-se": [10,[2]],
		"/(storefront)/entrar": [~21,[3],[4]],
		"/(storefront)/esqueci-a-senha": [~22,[3],[4]],
		"/(storefront)/esqueci-a-senha/sucesso": [24,[3],[4]],
		"/(storefront)/esqueci-a-senha/[token]": [~23,[3],[4]],
		"/(storefront)/faq": [25,[3],[4]],
		"/(storefront)/loja": [26,[3],[4]],
		"/(storefront)/loja/categoria/[...slugs]": [~27,[3],[4]],
		"/(storefront)/loja/produto/[slug]": [~28,[3],[4]],
		"/(storefront)/minha-conta": [29,[3,9],[4]],
		"/(storefront)/minha-conta/downloads": [~30,[3,9],[4]],
		"/(storefront)/minha-conta/pedidos": [~31,[3,9],[4]],
		"/(storefront)/minha-conta/pedidos/[order]": [~32,[3,9],[4]],
		"/(storefront)/(checkout)/pedido/confirmado": [~13,[5,7]],
		"/(storefront)/(checkout)/pedido/finalizar": [~14,[5,7]],
		"/(storefront)/politica-de-privacidade": [33,[3],[4]],
		"/(storefront)/sobre-nos": [34,[3],[4]],
		"/(storefront)/termos-e-condicoes": [35,[3],[4]],
		"/test": [36],
		"/(storefront)/[...notFound]": [15,[3],[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';